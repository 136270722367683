import * as styles from "./privacy.module.scss";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";
import clsx from "clsx";

export default function Privacy() {
  return (
    <Layout>
      <SeoHelmet title="Privacy Policy" />
      <Banner title="Privacy Policy" image="landing" />
      <div className={styles.pageContent}>
        <p className={clsx(styles.intro, styles.topSpacer)}>
          No cookies or tracking on this site. We collect some anonymous statistics from
          Netlify.
        </p>
        <p className={styles.intro}>
          <Link to="/">Home</Link>
        </p>
        <div className={styles.bottomSpacer} />
      </div>
    </Layout>
  );
}
